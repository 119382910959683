import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Styled Button Component
const Button = styled.button`
  font-family: 'Open Sans', sans-serif;
  background-color: #bbd7ec;
  font-weight: 300;
  color: #000000;
  padding: 2rem 4rem;
  border: none;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  max-width: 500px;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.4s ease;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  
  &:hover {
    background-color: #6bace9; /* Darker shade of blue */
    transform: translateY(-4px); /* More prominent lift on hover */
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3); /* Slight shadow effect on hover */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(107, 172, 233, 0.5);
  }

  &:active {
    background-color: #486fb4; /* Darker active color */
    transform: translateY(2px); /* Slight push effect */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateX(-50%) translateY(-100%);
    transition: transform 0.4s ease;
  }

  &:hover::before {
    transform: translateX(-50%) translateY(0);
  }
`;

// Centering wrapper for the button
const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0;

`;

const SubmitButton = ({ text = "Submit", to = "/", type = "button" }) => {
  return (
    <CenteredWrapper>
      <Link to={to}>
        <Button type={type}>
          {text}
        </Button>
      </Link>
    </CenteredWrapper>
  );
};

export default SubmitButton;