import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FAQDropdown from '../../components/FAQDropdown';


const faqData = [
  {
    question: "Why does my small business need a website?",
    answer:
      "A website increases your visibility, builds credibility, and provides an online presence where potential customers can learn about your services 24/7. Even for small, local businesses, a website serves as a digital storefront.",
  },
  {
    question: "How long does it take to build my website?",
    answer:
      "The timeline depends on the complexity of the website, but for small business sites, it usually takes between 4 to 6 weeks. This allows for planning, design, development, and revisions to ensure the site meets your needs.",
  },
  {
    question: "Will my website work on mobile phones?",
    answer:
      "Absolutely. Every website I create is fully responsive, meaning it will work seamlessly on smartphones, tablets, and desktop computers. Since most users access websites on their phones, mobile optimization is a priority.",
  },
  {
    question: "Can you help with getting more customers to my website?",
    answer:
      "Yes, I offer services like Search Engine Optimization (SEO) and digital marketing strategies that help drive traffic to your site, increasing your online visibility and bringing in more potential customers.",
  },
  {
    question: "Do you offer ongoing support and maintenance?",
    answer:
      "Yes. I offer ongoing maintenance plans to ensure your website remains up-to-date, secure, and performing well. This includes updates, backups, and resolving any technical issues that may arise.",
  },
  {
    question: "How much will my website cost?",
    answer:
      "The cost of a website depends on your specific needs, but for a typical small business website, prices start around $2,500. I offer transparent pricing and can work with you to create a solution that fits your budget.",
  },
  {
    question: "What if I don’t know what to put on my website?",
    answer:
      "I can guide you through the content creation process. We’ll discuss your services, target audience, and goals for the website to ensure the content effectively represents your business and engages customers.",
  },
  {
    question: "Can I update my website myself after it's built?",
    answer:
      "Yes. I build websites on user-friendly platforms like WordPress or custom content management systems (CMS) that allow you to easily make updates yourself. I also provide tutorials if needed.",
  },
  {
    question: "Will my website be found on Google?",
    answer:
      "Yes. Every website I build is optimized for search engines (SEO), which helps improve your website's ranking on Google. I also offer additional SEO services if you want to increase your site's visibility further.",
  },
  {
    question: "Can I add online booking or contact forms to my site?",
    answer:
      "Absolutely. I can integrate features like online booking, contact forms, and more, making it easy for customers to connect with your business directly through your website.",
  },
];


const PageContainer = styled('div')(({ theme }) => ({
    fontFamily: 'Noto Sans, sans-serif',
    backgroundColor: '#000000',
    padding: '12.5rem 2.5rem 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh', // Ensure full viewport height
    [theme.breakpoints.down('md')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
  }));

const ContentContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Noto Sans, sans-serif',
  width: '100%',
  maxWidth: '600px', // Reduced maxWidth to match image width
  backgroundColor: '#000000',
  padding: '30px', // Adjusted padding for better spacing
  borderRadius: '20px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginTop: '5px',
  border: '1px solid #ECE7E3',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%', // Decrease content width on smaller screens
  },
}));

const SectionTitle = styled(Typography)({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  margin: '10px auto', // Increase margin to create more space before section
  fontWeight: 'normal',
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)', // Add text shadow
  textAlign: 'left', // Align the title text to the left
});

const SectionContent = styled('div')({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  fontWeight: 'normal',
  marginBottom: '10px', // Add margin to the bottom for consistent spacing
  lineHeight: '1.5',
  maxWidth: '100%', // Adjust maximum width for better readability
  justifyContent: 'center', // Center the content within the container
  textAlign: 'left', // Align text and image to the left
});

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%', // Ensure the image is responsive
  maxWidth: '650px', // Limit the max width of the image
  height: 'auto', // Maintain aspect ratio
  borderRadius: '0px', // Add some border radius for style
  marginTop: '10px', // Add some space between the text and the image
  marginBottom: '10px', // Add some space between the text and the image
  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.5)',

}));

const WebDevPage = () => {
  return (
    <PageContainer>
      <FAQDropdown faqTitle="Frequently Asked Questions About Web Development" faqs={faqData} />  

 
    </PageContainer>
  );
};

export default WebDevPage;
