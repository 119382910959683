import React, { Suspense, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import Hero2 from '../components/Hero2';
import ContactForm from '../components/ContactForm';

const HomeServices = React.lazy(() => import('../components/homePageServices'));
const MadLib = React.lazy(() => import('../components/MadLib'));
const Reviews = React.lazy(() => import('../components/Reviews'));

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App" style={{ backgroundColor: '#000000' }}>
      <Helmet>
        <title>Build Me A Website - Top Web Development & Digital Marketing Agency</title>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=G-YYTCRX2PFK`}></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-YYTCRX2PFK');
            `}
          </script>
        <meta name="description" content="Build Me A Website offers cutting-edge web development and digital marketing services. Boost your online presence alongside a team that cares about your success." />
        <meta name="keywords" content="websites Midland, Midland website, Ontario websites, Canada cheap websites, Canadian Small Business Website Discounts, Free Custom Website, Websites, Search Engine Optimization, SEO, web development agency, Website company Barrie, Barrie websites, websites in barrie, websites in Ontario, cheap websites near me, web development, Ontario, Toronto, website design, web hosting, DNS management, SEO services, SEM strategies, digital marketing, responsive design, ecommerce solutions, UX/UI design, content management systems, website optimization, online branding, website maintenance, professional web services, web security, custom websites, domain registration, web analytics" />
        <link rel="canonical" href="https://www.buildmeawebsite.ca/" />
      </Helmet>

     {/* Main sections using Material-UI Grid */}
     <Grid container spacing={0}>
        <Grid item xs={12}>
          <Hero2 /> {/* Your Hero content */}
        </Grid>

    
          <Grid item xs={12}>
            <MadLib />
          </Grid>

          <Grid item xs={12}>
            <HomeServices />
          </Grid>
          <Grid item xs={12}>
            <ContactForm />
          </Grid>
          <Grid item xs={12}>
            <Reviews />
          </Grid>
       

        
   
      </Grid>
  
</div>
 
 
  );
}

export default HomePage;