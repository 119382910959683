import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import BlogPost1 from '../components/BlogPost1';
import BlogPost2 from '../components/BlogPost2';
import BlogPost3 from '../components/BlogPost3';
import BlogPost4 from '../components/BlogPost4';
import BlogPost5 from '../components/BlogPost5';
import BlogPost6 from '../components/BlogPost6';
import { Grid, Container, Typography } from '@mui/material';
import SubmitButton from '../components/SubmitButtonComponent';

const blogPosts = [
  {
    id: 'boosting-website-speed-seo',
    label: 'Boosting Website Speed for SEO',
    component: <BlogPost5 />,
    image: '/Images/web-design-2.jpg',
    description:
      'Learn how to optimize your website’s speed to improve user experience and SEO rankings.',
  },
  {
    id: 'why-react',
    label: 'Why React?',
    component: <BlogPost2 />,
    image: '/Images/web-development-2.jpg',
    description:
      'Explore the benefits of using React in web development, focusing on its component-based architecture and fast performance.',
  },
  {
    id: 'responsiveness-in-web-development',
    label: 'Responsiveness in Web Development',
    component: <BlogPost3 />,
    image: '/Images/web-development-3.jpg',
    description:
      'Understand the importance of creating responsive websites that adapt to various devices using techniques like media queries.',
  },
  {
    id: 'mastering-css-grid-flexbox',
    label: 'Mastering CSS Grid + Flexbox',
    component: <BlogPost4 />,
    image: '/Images/web-design-1.jpg',
    description:
      'Master two powerful CSS layout models, Grid and Flexbox, to create complex, responsive layouts with ease.',
  },
  {
    id: 'css',
    label: 'CSS',
    component: <BlogPost1 />,
    image: '/Images/web-development-1.jpg',
    description:
      'Learn the fundamentals of CSS, including cascading nature, responsiveness, and modern layout tools like Flexbox and Grid.',
  },
  {
    id: 'web-accessibility-inclusive-design',
    label: 'Web Accessibility: Inclusive Design',
    component: <BlogPost6 />,
    image: '/Images/web-design-3.jpg',
    description:
      'Discover the importance of building accessible websites for all users by following inclusive design principles.',
  },
];

const formatPostId = (id) => {
  return blogPosts.find((post) => post.id === id)?.label || 'CSS';
};

function BlogPage() {
  const { postId } = useParams();
  const [currentPost, setCurrentPost] = useState(formatPostId(postId));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const articleRef = useRef(null); // Ref to scroll to the article section
  const topRef = useRef(null); // Ref to scroll back to the top of the page

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setCurrentPost(formatPostId(postId));
  }, [postId]);

  const renderBlogPost = () => {
    const currentBlog = blogPosts.find((post) => post.label === currentPost);
    return currentBlog ? currentBlog.component : <BlogPost1 />;
  };

  const scrollToArticle = () => {
    if (articleRef.current) {
      articleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="App"
      ref={topRef} // Ref to the top of the page
      style={{ backgroundColor: '#000000', minHeight: '100vh', paddingTop: '210px' }}
    >
      <Helmet>
        <title>
          {currentPost} - Build Me A Website | Web Development Insights & Tips
        </title>
        <meta
          name="description"
          content={`Read about ${currentPost} on the Build Me A Website blog. Stay updated with the latest trends in web design, development, and digital marketing.`}
        />
        <link
          rel="canonical"
          href={`https://www.buildmeawebsite.ca/blog/${postId || 'css'}`}
        />
      </Helmet>

      <Container>
        <Grid container spacing={5} justifyContent="center">
          {blogPosts.map((post) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={post.id}
              onClick={() => {
                setCurrentPost(post.label); // Set the current post on click
                scrollToArticle(); // Scroll to the article
              }}
              style={{ 
                cursor: 'pointer',
                margin: '0 auto',
              }}
            >
              <img
                src={post.image}
                alt={post.label}
                style={{
                  width: '100%',
                }}
              />
              <Typography variant="h6" align="left" style={{ fontFamily: 'Noto Sans, sans-serif', color: '#bbd7ec', fontWeight: 100, fontSize: '25px' }}>
                {post.label}
              </Typography>
              <Typography variant="body1" align="left" style={{ fontFamily: 'Nunito, sans-serif', color: 'white', fontWeight: 100 }}>
                {post.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>

      <div ref={articleRef} style={{ backgroundColor: '#000000', paddingTop: '50px' }}>
        {renderBlogPost()}
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '50px' }}> {/* Add padding to create space from the footer */}
        <SubmitButton text="Read More" type="button" onClick={scrollToTop} />
      </div>
    </div>
  );
}

export default BlogPage;