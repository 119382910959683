import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FAQDropdown from '../../components/FAQDropdown';


const faqData = [
  {
    question: "What is Search Engine Marketing (SEM)?",
    answer:
      "Search Engine Marketing (SEM) is a paid strategy to promote your business on search engines like Google. It involves creating ads that appear at the top of search results when people search for terms related to your business.",
  },
  {
    question: "How is SEM different from SEO?",
    answer:
      "SEM focuses on paid ads that show up in search results, while SEO (Search Engine Optimization) is about improving your website to appear higher in organic, unpaid search results. SEM delivers faster results because you pay for the top spots.",
  },
  {
    question: "Why does my small business need SEM?",
    answer:
      "SEM helps you quickly get your business in front of people who are actively searching for your services. It drives traffic to your website, increases brand awareness, and helps you compete with larger businesses.",
  },
  {
    question: "How much does SEM cost?",
    answer:
      "The cost of SEM depends on how much you want to spend on ads. You can set a daily budget and only pay when someone clicks on your ad (this is called pay-per-click or PPC). We'll help you create a budget that fits your business goals.",
  },
  {
    question: "Will I see immediate results with SEM?",
    answer:
      "Yes, one of the benefits of SEM is that you can see results quickly. Once your ads are live, they’ll start appearing in search results, driving traffic to your site almost immediately.",
  },
  {
    question: "How do you choose the right keywords for my ads?",
    answer:
      "We perform keyword research to identify the search terms your potential customers are using. This helps us target the right audience, ensuring your ads appear when people are searching for products or services you offer.",
  },
  {
    question: "Can SEM help my business grow locally?",
    answer:
      "Yes, SEM is especially effective for local businesses. We can target ads to people searching in your area, helping you attract more local customers who are ready to visit your store or use your services.",
  },
  {
    question: "How do you track the success of my SEM campaigns?",
    answer:
      "We provide detailed reports that show how many people saw your ads, how many clicked on them, and what actions they took on your website (like filling out a contact form or making a purchase). This helps measure your return on investment (ROI).",
  },
  {
    question: "Can SEM help increase my online sales?",
    answer:
      "Yes, SEM can help increase online sales by driving more qualified traffic to your website. People clicking on your ads are already interested in what you offer, making them more likely to convert into customers.",
  },
  {
    question: "What types of businesses benefit the most from SEM?",
    answer:
      "Any business that wants to increase online visibility and drive traffic can benefit from SEM. Whether you're a local service provider or an e-commerce store, SEM helps you reach the right audience at the right time.",
  },
];


const PageContainer = styled('div')(({ theme }) => ({
    fontFamily: 'Noto Sans, sans-serif',
    backgroundColor: '#000000',
    padding: '12.5rem 2.5rem 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh', // Ensure full viewport height
    [theme.breakpoints.down('md')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
  }));

const ContentContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Noto Sans, sans-serif',
  width: '100%',
  maxWidth: '600px', // Reduced maxWidth to match image width
  backgroundColor: '#000000',
  padding: '30px', // Adjusted padding for better spacing
  borderRadius: '20px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginTop: '5px',
  border: '1px solid #ECE7E3',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%', // Decrease content width on smaller screens
  },
}));

const SectionTitle = styled(Typography)({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  margin: '10px auto', // Increase margin to create more space before section
  fontWeight: 'normal',
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)', // Add text shadow
  textAlign: 'left', // Align the title text to the left
});

const SectionContent = styled('div')({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  fontWeight: 'normal',
  marginBottom: '10px', // Add margin to the bottom for consistent spacing
  lineHeight: '1.5',
  maxWidth: '100%', // Adjust maximum width for better readability
  justifyContent: 'center', // Center the content within the container
  textAlign: 'left', // Align text and image to the left
});

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%', // Ensure the image is responsive
  maxWidth: '650px', // Limit the max width of the image
  height: 'auto', // Maintain aspect ratio
  borderRadius: '0px', // Add some border radius for style
  marginTop: '10px', // Add some space between the text and the image
  marginBottom: '10px', // Add some space between the text and the image
  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.5)',

}));

const SEMPage = () => {
  return (
    <PageContainer>
      <FAQDropdown faqTitle="Frequently Asked Questions About Web Development" faqs={faqData} />  

 
    </PageContainer>
  );
};

export default SEMPage;

