import React, { useState } from 'react';
import { FaInstagram, FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa'; 
import styles from './Footer3.module.css'; // Import CSS module

const Footer3 = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const handleMouseEnter = (icon) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.messageSection}>
        <h1 className={styles.message}>Websites for small businesses</h1>
      </div>

      <div className={styles.footer}>
        <div className={styles.socialMedia}>
          <a
            href="https://www.instagram.com/buildmeawebsite"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.icon}
            onMouseEnter={() => handleMouseEnter('instagram')}
            onMouseLeave={handleMouseLeave}
          >
            <FaInstagram />
          </a>
          <a
            href="https://x.com/BuildMeWebsites"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.icon}
            onMouseEnter={() => handleMouseEnter('twitter')}
            onMouseLeave={handleMouseLeave}
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.linkedin.com/company/104606833/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.icon}
            onMouseEnter={() => handleMouseEnter('linkedin')}
            onMouseLeave={handleMouseLeave}
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.facebook.com/buildmecanada"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.icon}
            onMouseEnter={() => handleMouseEnter('facebook')}
            onMouseLeave={handleMouseLeave}
          >
            <FaFacebook />
          </a>
        </div>

        <div>
          <p>© BUILDME DIGITAL MARKETING</p>
        </div>
      </div>
    </div>
  );
};

export default Footer3;