import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components
const FAQContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 1rem;
  font-family: 'Noto Sans', sans-serif;
`;

const FAQTitle = styled.h2`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const FAQItem = styled.div`
  border-bottom: 1px solid #bbd7ec;
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Question = styled.p`
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
`;

const Answer = styled.p`
text-align: left;
  margin-top: 1rem;
  font-size: 1rem;
  color: #bbd7ec;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const ArrowIcon = styled.span`
  font-size: 1.5rem;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  color: white;
  transition: transform 0.3s ease;
`;

const FAQDropdown = ({ faqs = [], title = "Frequently Asked Questions" }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FAQContainer>
      <FAQTitle>{title}</FAQTitle>
      {faqs.length > 0 ? (
        faqs.map((faq, index) => (
          <div key={index}>
            <FAQItem onClick={() => handleToggle(index)}>
              <Question>{faq.question}</Question>
              <ArrowIcon isOpen={openIndex === index}>▼</ArrowIcon>
            </FAQItem>
            <Answer isOpen={openIndex === index}>{faq.answer}</Answer>
          </div>
        ))
      ) : (
        <p>No FAQs available.</p>
      )}
    </FAQContainer>
  );
};

export default FAQDropdown;