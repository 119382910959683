import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './BlogPost.module.css'; // Import CSS module

const BlogPost1 = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <title>Understanding CSS: The Language of Web Design - Build Me A Website</title>
        <meta
          name="description"
          content="Learn about CSS, the language that controls the presentation of web pages. This article covers everything from CSS basics to advanced techniques like Flexbox and Grid."
        />
      </Helmet>
      <header className={styles.header}>
        <h1 className={styles.title}>Understanding CSS: The Language of Web Design</h1>
      </header>
      <main className={styles.mainContent}>
        <section>
          <p className={styles.firstParagraph}>
            <span className={styles.dropCap}>C</span>
            ascading Style Sheets (<a href="https://developer.mozilla.org/en-US/docs/Web/CSS" className={styles.linkStyle}>CSS</a>) is the backbone of modern web design. It’s the language that allows us to bring websites to life, giving structure and style to otherwise plain text and images. Whether you're just starting your journey into web development or looking to master advanced techniques, CSS is a tool you'll always rely on.
          </p>
          <p className={styles.otherParagraph}>
            One of the core concepts of CSS is its <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Cascade" className={styles.linkStyle}>cascading nature</a>. The cascade lets multiple style rules combine, which gives us control over how styles are applied based on specificity and importance. It’s like having layers of paint on a canvas, each one enhancing or tweaking the final masterpiece. But if you've ever tried to figure out why a certain style wasn't working as expected, you've probably experienced the sometimes puzzling nature of the cascade!
          </p>
          <p className={styles.otherParagraph}>
            Another powerful aspect of CSS is its ability to adapt to different screen sizes. This is where <a href="https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Responsive_Design" className={styles.linkStyle}>responsive design</a> comes into play. With media queries and flexible layout options, CSS ensures that your content looks great on any device, from a tiny smartphone to a massive desktop monitor. There's nothing quite as satisfying as seeing your design magically rearrange itself to fit perfectly on every screen size.
          </p>
        </section>

        <section>
          <p className={styles.otherParagraph}>
            <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Flexbox" className={styles.linkStyle}>Flexbox</a> is one of the best tools in the modern web developer’s toolkit. It simplifies the process of laying out items within a container and ensures that they are spaced and aligned just right. I remember the first time I used Flexbox—I was amazed at how easily I could center elements on the page. Gone were the days of complicated margin calculations or awkward hacks!
          </p>
          <p className={styles.otherParagraph}>
            Then came <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout" className={styles.linkStyle}>CSS Grid</a>, which took layout control to another level. Unlike Flexbox, which is one-dimensional (either rows or columns), CSS Grid is a two-dimensional layout system that allows you to control both rows and columns simultaneously. I often find myself using CSS Grid for more complex layouts, especially when I want to ensure perfect alignment across the entire page.
          </p>
        </section>

        <section>
          <p className={styles.otherParagraph}>
            One question I often hear is: "When should I use Flexbox, and when should I use Grid?" The answer depends on the complexity of your layout. For simple one-dimensional layouts (either rows or columns), <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Flexbox" className={styles.linkStyle}>Flexbox</a> is perfect. But for more complex, two-dimensional designs where you need to define both rows and columns, <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout" className={styles.linkStyle}>CSS Grid</a> shines.
          </p>
          <p className={styles.otherParagraph}>
            Of course, responsive design isn’t just about layouts. <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/media_queries" className={styles.linkStyle}>Media queries</a> allow developers to tailor their designs to different devices. By setting specific rules for different screen widths, we ensure that the user experience remains consistent whether they’re on mobile, tablet, or desktop. It’s one of the reasons CSS is such a versatile and powerful tool—your website can literally transform itself to meet the needs of your audience.
          </p>
        </section>

        <section>
          <p className={styles.otherParagraph}>
            If you’re serious about mastering CSS, you’ll want to take a look at pre-processors like <a href="https://sass-lang.com/" className={styles.linkStyle}>Sass</a> or <a href="https://lesscss.org/" className={styles.linkStyle}>Less</a>. These tools give you features like variables, nesting, and mixins, which can make your code much more efficient and easier to maintain. I remember when I first switched from writing plain CSS to using Sass—it was a game changer! Suddenly, organizing my stylesheets felt intuitive, and making global changes was a breeze.
          </p>
          <p className={styles.otherParagraph}>
            Lastly, let’s talk about one of the most exciting developments in recent CSS history: <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties" className={styles.linkStyle}>CSS variables (custom properties)</a>. These allow developers to store values (like colors, fonts, or dimensions) and reuse them throughout the stylesheet. No more searching through lines of code to make changes—just update your variable, and the entire design is updated instantly.
          </p>
          <p className={styles.otherParagraph}>
            In conclusion, CSS is more than just a language for styling; it’s a tool that gives developers the freedom to create visually stunning and responsive websites. Whether you’re a seasoned developer or just starting out, embracing the latest CSS techniques will make your design process smoother and more enjoyable. From Flexbox to Grid, media queries to custom properties—each advancement in CSS brings us closer to a more dynamic and accessible web.
          </p>
        </section>

      </main>
    </div>
  );
};

export default BlogPost1;