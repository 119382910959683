import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import Header from './components/Header';
import AboutPage from './pages/AboutPage';
import ScrollToTop from './components/ScrollToTop';
import HomePage from './pages/Home';
import BlogPage from './pages/Blog';
import ConsultationPage from './pages/Consultation';
import './global.css';
import BlogPost1 from './components/BlogPost1';
import BlogPost2 from './components/BlogPost2';
import BlogPost3 from './components/BlogPost3';
import BlogPost4 from './components/BlogPost4';
import BlogPost5 from './components/BlogPost5';
import BlogPost6 from './components/BlogPost6';
import Footer3 from './components/Footer3';
import SeoPage from './pages/ServicesPages/SEOPage';
import WebHostingPage from './pages/ServicesPages/WebHostingPage';
import SEMPage from './pages/ServicesPages/SEMPage';
import DNSMigrationPage from './pages/ServicesPages/DNSMigrationPage';
import WebDevPage from './pages/ServicesPages/WebDevPage';
import WebDesignPage from './pages/ServicesPages/WebDesignPage';

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    const script1 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-YYTCRX2PFK';
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-YYTCRX2PFK');
    `;
    document.head.appendChild(script2);
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Build Me A Website - Professional Web Development Services</title>
          <meta name="description" content="Build Me A Website offers professional and affordable web development services tailored to your business needs. Get in touch with us today!" />
          <meta name="keywords" content="web development, website design, web hosting, DNS management, SEO services, SEM strategies, digital marketing, responsive design, ecommerce solutions, UX/UI design, content management systems, website optimization, online branding, website maintenance, professional web services, web security, custom websites, domain registration, web analytics" />
          
          {/* OpenGraph and Twitter Meta Tags */}
          <meta property="og:title" content="Build Me A Website - Professional Web Development Services" />
          <meta property="og:description" content="Build Me A Website offers professional and affordable web development services tailored to your business needs. Get in touch with us today!" />
          <meta property="og:url" content="https://www.buildmeawebsite.ca/" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://www.buildmeawebsite.ca/logo.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Build Me A Website - Professional Web Development Services" />
          <meta name="twitter:description" content="Explore beauty tutorials, product reviews, and travel tips by Jackie Wyers." />
          <meta name="twitter:image" content="https://www.buildmeawebsite.ca/logo.png" />

          {/* Preloading styles */}
          <link rel="preload" href="/assets/styles/main.css" as="style" />
          <link rel="stylesheet" href="https://www.buildmeawebsite.ca/assets/styles/main.css" />
        </Helmet>

        <Header />
        <ScrollToTop />

     
        
      
      

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/consultation" element={<ConsultationPage />} />

            {/* Blog Post Routes */}
            <Route path="/blog/css" element={<BlogPost1 />} />
            <Route path="/blog/why-react" element={<BlogPost2 />} />
            <Route path="/blog/responsiveness-in-web-development" element={<BlogPost3 />} />
            <Route path="/blog/mastering-css-grid-flexbox" element={<BlogPost4 />} />
            <Route path="/blog/boosting-website-speed-seo" element={<BlogPost5 />} />
            <Route path="/blog/web-accessibility-inclusive-design" element={<BlogPost6 />} />

            {/* Service Pages Routes */}
            <Route path="/services/web-hosting" element={<WebHostingPage />} />
            <Route path="/services/search-engine-optimization" element={<SeoPage />} />
            <Route path="/services/search-engine-marketing" element={<SEMPage />} />
            <Route path="/services/dns-migration" element={<DNSMigrationPage />} />
            <Route path="/services/web-development" element={<WebDevPage />} />
            <Route path="/services/web-design" element={<WebDesignPage />} />
          </Routes>
   

        <Footer3 />
      </Router>
    </HelmetProvider>
  );
}

export default App;