import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';

const Contact = styled('div')(({ theme }) => ({
  fontFamily: 'Open Sans, sans-serif',
  backgroundColor: '#000000',
  minHeight: '60vh',
  paddingTop: '70px',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '2rem 2rem 2rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 2rem 2rem',
  },
}));

const FormContainer = styled('form')(({ theme }) => ({
  fontFamily: 'Open Sans, sans-serif',
  width: '100%',
  maxWidth: '350px',
  backgroundColor: '#000000',
  padding: '0.1rem',
  borderRadius: '2px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
    padding: '20px',
  },
}));

const FieldContainer = styled('div')({
  backgroundColor: '#000000',
});



const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Open Sans, sans-serif',
  backgroundColor: '#bbd7ec',
  color: '#000000',
  fontSize: '1.5rem',
  padding: '1rem 2rem',
  fontWeight: '300',
  

  transition: 'transform 0.05s ease, box-shadow 0.25s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#000000',
    color: '#bbd7ec',

  },
  '&:active': {
    transform: 'scale(0.95)',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#bbd7ec',
  },
}));

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
    services: {
      webDevelopment: false,
      seoOptimization: false,
      uiUxDesign: false,
      digitalMarketing: false,
      webDesign: false,
      dnsMigration: false,
    },
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      services: {
        ...formValues.services,
        [name]: checked,
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('https://buildmywebsite-server-231b6957e9dd.herokuapp.com/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        console.error('Form submission failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Contact>
      {submitted ? (
        <Typography
          variant="h3"
          align="center"
          style={{ fontFamily: 'Noto Sans, sans-serif', color: 'white' , fontWeight: '100' , padding: '5rem 1rem', margin:'0 auto' }}
        >
          Thank you! We will be in touch within 1 business day.
        </Typography>
      
      ) : (
        <>
          <Typography variant="h2" align="center" gutterBottom style={{ fontFamily: 'Noto Sans, sans-serif', color: 'white' }}>
            Free Consultation
          </Typography>
          <Typography variant="body1" align="center" gutterBottom style={{ fontFamily: 'Open Sans, sans-serif', color: 'white', marginBottom : "1.25rem" }}>
            Fill out the form below to get in touch with us and let us know how we can assist you.
          </Typography>
          <FormContainer onSubmit={handleSubmit}>
            <FieldContainer>
              <TextField
                style={{ backgroundColor: '#000000', fontFamily: 'Open Sans, sans-serif' , color : '#ffffff'}}
                label="Full Name"
                name="name"
                variant="outlined"
                fullWidth
                value={formValues.name}
                onChange={handleChange}
                InputLabelProps={{
                  style: { color: 'white', fontFamily: 'Open Sans, sans-serif' },
                }}
                InputProps={{
                  style: { color: 'white' }, // Change input text color to white
                  sx: {
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: 'white', // Set border color to white when focused
                    },
                  },
                }}
                placeholder="Full Name"
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                style={{ backgroundColor: '#000000', fontFamily: 'Open Sans, sans-serif' , color : 'white'}}
                label="Email Address"
                name="email"
                variant="outlined"
                fullWidth
                value={formValues.email}
                onChange={handleChange}
                InputLabelProps={{
                  style: { color: 'white', fontFamily: 'Open Sans, sans-serif' },
                }}
                InputProps={{
                  style: { color: 'white' }, // Change input text color to white
                  sx: {
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: 'white', // Set the focus border color to white
                    },
                  },
                }}
                placeholder="Email Address"
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                style={{ backgroundColor: '#000000', fontFamily: 'Open Sans, sans-serif' , color : 'white'}}
                label="Business Number"
                name="businessNumber"
                variant="outlined"
                fullWidth
                value={formValues.businessNumber}
                onChange={handleChange}
                InputLabelProps={{
                  style: { color: '#ffffff', fontFamily: 'Open Sans, sans-serif' },
                }}
                InputProps={{
                  style: { color: '#ffffff' }, // Change input text color to white
                  sx: {
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: 'white', // Set the focus border color to white
                    },
                  },
                }}
                placeholder="Business Number"
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                style={{ backgroundColor: '#000000', fontFamily: 'Open Sans, sans-serif' , color : 'white'}}
                label="Business Name"
                name="businessName"
                variant="outlined"
                fullWidth
                value={formValues.businessName}
                onChange={handleChange}
                InputLabelProps={{
                  style: { color: 'white', fontFamily: 'Open Sans, sans-serif' },
                }}
                InputProps={{
                  style: { color: 'white' }, // Change input text color to white
                  sx: {
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: 'white', // Set the focus border color to white
                    },
                  },
                }}
                placeholder="Business Name"
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                style={{ backgroundColor: '#000000', fontFamily: 'Open Sans, sans-serif' , color : 'white'}}
                label="Message"
                name="message"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={formValues.message}
                onChange={handleChange}
                InputLabelProps={{
                  style: { color: 'white', fontFamily: 'Open Sans, sans-serif' },
                }}
                InputProps={{
                  style: { color: 'white' }, // Change input text color to white
                  sx: {
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: 'white', // Set the focus border color to white
                    },
                  },
                }}
                placeholder="Message"
              />
            </FieldContainer>
           
            <StyledButton type="submit" fullWidth>
              Submit
            </StyledButton>
          </FormContainer>
        </>
      )}
    </Contact>
  );
};

export default ContactForm;