import React, { useState, useEffect } from 'react';
import styles from './Hero2.module.css'; // Import the CSS module

// Set the image path using PUBLIC_URL
const backgroundImage = `${process.env.PUBLIC_URL}/Images/bg2.jpg`;

const Hero2 = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`${styles.main} ${isLoading ? styles.isLoading : ''}`}
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 100%, transparent), url(${backgroundImage})`,
      }}
    >
      <h1 className={styles.heading}> <span> buildmeawebsite.ca </span> </h1>
     

    </div>
  );
};

export default Hero2;