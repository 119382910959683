import React, { useEffect, useState } from 'react';
import styles from './LaptopsComponent.module.css';

const LaptopsComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the animation after a slight delay to simulate sliding effect
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.container}>
      <img
        src={`${process.env.PUBLIC_URL}/Images/leftlaptop.png`}
        alt="Laptop 1"
        className={`${styles.image} ${styles.slideInLeft} ${isVisible ? styles.visible : ''}`}
      />
      <img
        src={`${process.env.PUBLIC_URL}/Images/laptop2.png`}
        alt="Laptop 2"
        className={`${styles.image} ${styles.slideInRight} ${isVisible ? styles.visible : ''}`}
      />
    </div>
  );
};

export default LaptopsComponent;