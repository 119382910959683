import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FAQDropdown from '../../components/FAQDropdown';


const faqData = [
  {
    question: "What is SEO, and why does my business need it?",
    answer:
      "SEO (Search Engine Optimization) is the process of improving your website’s visibility on search engines like Google. It helps your business appear higher in search results, which drives more traffic to your site and increases the chances of converting visitors into customers.",
  },
  {
    question: "How long does it take to see results from SEO?",
    answer:
      "SEO is a long-term strategy, and it typically takes 3 to 6 months to start seeing significant improvements in rankings and traffic. However, this depends on the competitiveness of your industry and the keywords you are targeting.",
  },
  {
    question: "What is the difference between SEO and paid advertising?",
    answer:
      "SEO focuses on organic (unpaid) search results, while paid advertising (such as Google Ads) involves paying for placement in search results. SEO provides long-term value and trust, while paid ads offer immediate visibility but stop as soon as you stop paying.",
  },
  {
    question: "Will my business show up on the first page of Google?",
    answer:
      "While I can’t guarantee first-page results (no one can), I follow best practices and proven strategies to improve your search rankings over time. Factors like competition, keywords, and website quality all affect where your business ranks on Google.",
  },
  {
    question: "How do you choose keywords for SEO?",
    answer:
      "I conduct thorough keyword research based on your industry, target audience, and competitors. The goal is to find keywords that potential customers are searching for, which are relevant to your business and have a good balance of competition and search volume.",
  },
  {
    question: "What’s the difference between on-page and off-page SEO?",
    answer:
      "On-page SEO refers to optimizing content on your website (like keywords, titles, and meta tags), while off-page SEO involves external factors like building backlinks from other sites, which help increase your site’s authority and ranking.",
  },
  {
    question: "How do you track SEO performance?",
    answer:
      "I use tools like Google Analytics, Google Search Console, and other SEO software to track metrics such as traffic, keyword rankings, bounce rates, and conversions. Regular reports will be provided to show how your SEO is improving.",
  },
  {
    question: "Can SEO help my local business?",
    answer:
      "Yes, local SEO is essential for businesses that serve a specific geographic area. I optimize your site for local search queries and ensure your business appears in Google’s Local Pack and Google Maps, helping nearby customers find you more easily.",
  },
  {
    question: "How much does SEO cost?",
    answer:
      "SEO pricing depends on the scope of the work, your industry, and the competitiveness of your keywords. I offer customized SEO plans to fit different budgets, whether you're a small business or looking for a more extensive SEO campaign.",
  },
  {
    question: "What are backlinks, and why are they important?",
    answer:
      "Backlinks are links from other websites that point to your website. They are crucial for off-page SEO because they act as ‘votes of confidence’ for your site, telling search engines that your content is valuable and authoritative.",
  },
];


const PageContainer = styled('div')(({ theme }) => ({
    fontFamily: 'Noto Sans, sans-serif',
    backgroundColor: '#000000',
    padding: '12.5rem 2.5rem 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh', // Ensure full viewport height
    [theme.breakpoints.down('md')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
  }));

const ContentContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Noto Sans, sans-serif',
  width: '100%',
  maxWidth: '600px', // Reduced maxWidth to match image width
  backgroundColor: '#000000',
  padding: '30px', // Adjusted padding for better spacing
  borderRadius: '20px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginTop: '5px',
  border: '1px solid #ECE7E3',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%', // Decrease content width on smaller screens
  },
}));

const SectionTitle = styled(Typography)({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  margin: '10px auto', // Increase margin to create more space before section
  fontWeight: 'normal',
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)', // Add text shadow
  textAlign: 'left', // Align the title text to the left
});

const SectionContent = styled('div')({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  fontWeight: 'normal',
  marginBottom: '10px', // Add margin to the bottom for consistent spacing
  lineHeight: '1.5',
  maxWidth: '100%', // Adjust maximum width for better readability
  justifyContent: 'center', // Center the content within the container
  textAlign: 'left', // Align text and image to the left
});

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%', // Ensure the image is responsive
  maxWidth: '650px', // Limit the max width of the image
  height: 'auto', // Maintain aspect ratio
  borderRadius: '0px', // Add some border radius for style
  marginTop: '10px', // Add some space between the text and the image
  marginBottom: '10px', // Add some space between the text and the image
  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.5)',

}));

const SeoPage = () => {
  return (
    <PageContainer>
      <FAQDropdown faqTitle="Frequently Asked Questions About Web Development" faqs={faqData} />  


    </PageContainer>
  );
};

export default SeoPage;
