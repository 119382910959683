import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #000000;
  color: #bbd7ec;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh;
  padding-top: 200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
  @media (max-width: 768px) {
    padding-top: 120px;
  }
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SmallText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  font-weight: 300;
  margin: 1rem auto;
  color: #bbd7ec;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const BigText = styled.h1`
  font-family: 'Noto Sans', sans-serif;
  font-size: 125px;
  font-weight: 300;
  margin: 0 auto;
  color: #bbd7ec;

  @media (max-width: 768px) {
    font-size: 125px;
  }

  @media (max-width: 480px) {
    font-size: 125px;
  }
`;

const FooterText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
    font-weight: 300;

  color: #bbd7ec;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center; /* Align items within FooterText to the center */

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const GoogleLogo = styled.img`
  width: 50px;
  margin-left: 5px;
`;

const StatComponent = () => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the animation on page load
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Small delay to allow the transition effect

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const target = 300;
      const increment = 1;
      const duration = 2000;
      const intervalTime = Math.floor(duration / (target / increment));

      const interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount < target) {
            return prevCount + increment;
          } else {
            clearInterval(interval);
            return target;
          }
        });
      }, intervalTime);

      return () => clearInterval(interval);
    }
  }, [isVisible]);

  return (
    <Container id="stat-component" className={isVisible ? 'visible' : ''}>
      <SmallText>Businesses that optimize their web presence see</SmallText>
      <BigText>{count}%</BigText>
      <SmallText>increase in visibility and customer engagement within the first year.</SmallText>
      <FooterText>
        Source <strong>: Google Economic Impact Report</strong>
        <GoogleLogo src={`${process.env.PUBLIC_URL}/Images/google.png`} alt="Google Logo" />
      </FooterText>
    </Container>
  );
};

export default StatComponent;