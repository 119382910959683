import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FAQDropdown from '../../components/FAQDropdown';


const faqData = [
  {
    question: "What is web hosting and why do I need it?",
    answer:
      "Web hosting provides the necessary space for your website on the internet. Without it, your website wouldn’t be accessible to customers. It’s like renting space for your business online where all your website files are stored and managed.",
  },
  {
    question: "What is a professional business email?",
    answer:
      "A professional business email uses your domain name, like you@yourbusiness.com, which gives your business a more credible and trustworthy appearance compared to free email services like Gmail or Yahoo.",
  },
  {
    question: "Can I use my existing domain name for hosting and email?",
    answer:
      "Yes, if you already have a domain, we can help you set up web hosting and professional email services using your current domain, ensuring all your online services are connected.",
  },
  {
    question: "Will my website be secure and protected?",
    answer:
      "Yes. We provide essential security features, including SSL certificates for data encryption, daily backups, and malware protection, ensuring your website is safe and secure from potential threats.",
  },
  {
    question: "How much does web hosting and business email cost?",
    answer:
      "The cost depends on your specific needs, but for small businesses, we offer affordable packages that include both hosting and email services. We’ll work with your budget to provide the best solution for your business.",
  },
  {
    question: "Can I get a custom email address for each of my employees?",
    answer:
      "Yes. You can set up multiple professional business emails, such as employee@yourbusiness.com. This allows your whole team to have custom email addresses associated with your domain.",
  },
  {
    question: "What happens if my website has an issue?",
    answer:
      "Our hosting services include 24/7 monitoring and support. If your site experiences any problems, our team will quickly identify and resolve the issue, ensuring minimal downtime for your business.",
  },
  {
    question: "Will my website be fast and reliable?",
    answer:
      "Yes. Our hosting services are optimized for performance, ensuring fast loading times and reliable service. We also monitor your website to make sure it stays online and responsive.",
  },
  {
    question: "Can I access my business email on my phone or computer?",
    answer:
      "Absolutely. You can access your business email on any device, including phones, tablets, and computers. Our email service is compatible with popular email apps like Outlook, Gmail, and Apple Mail.",
  },
  {
    question: "Do you provide regular backups for my website?",
    answer:
      "Yes, we provide daily backups for your website. This ensures that if anything happens to your site, we can quickly restore it to a previous version, minimizing any potential loss of data.",
  },
];

const PageContainer = styled('div')(({ theme }) => ({
    fontFamily: 'Noto Sans, sans-serif',
    backgroundColor: '#000000',
    padding: '12.5rem 2.5rem 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh', // Ensure full viewport height
    [theme.breakpoints.down('md')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
  }));

const ContentContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Noto Sans, sans-serif',
  width: '100%',
  maxWidth: '600px', // Reduced maxWidth to match image width
  backgroundColor: '#000000',
  padding: '30px', // Adjusted padding for better spacing
  borderRadius: '20px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginTop: '5px',
  border: '1px solid #ECE7E3',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%', // Decrease content width on smaller screens
  },
}));

const SectionTitle = styled(Typography)({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  margin: '10px auto', // Increase margin to create more space before section
  fontWeight: 'normal',
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)', // Add text shadow
  textAlign: 'left', // Align the title text to the left
});

const SectionContent = styled('div')({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  fontWeight: 'normal',
  marginBottom: '10px', // Add margin to the bottom for consistent spacing
  lineHeight: '1.5',
  maxWidth: '100%', // Adjust maximum width for better readability
  justifyContent: 'center', // Center the content within the container
  textAlign: 'left', // Align text and image to the left
});

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%', // Ensure the image is responsive
  maxWidth: '650px', // Limit the max width of the image
  height: 'auto', // Maintain aspect ratio
  borderRadius: '0px', // Add some border radius for style
  marginTop: '10px', // Add some space between the text and the image
  marginBottom: '10px', // Add some space between the text and the image
  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.5)',

}));

const WebHostingPage = () => {
  return (
    <PageContainer>
      <FAQDropdown faqTitle="Frequently Asked Questions About Web Development" faqs={faqData} />  

    </PageContainer>
  );
};

export default WebHostingPage;
