import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

const logo = `${process.env.PUBLIC_URL}/Images/logo13.png`;

const NavigationMenuBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [showHeader, setShowHeader] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [menuOpen, setMenuOpen] = useState(false); // State for fullscreen menu

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle fullscreen menu
    console.log("Menu state toggled. Current menuOpen state:", !menuOpen); // Debug message
  };

 

  return (
    <header className={`${styles.header} ${showHeader ? styles.headerShow : styles.headerHide}`}>
      <nav className={styles.nav}>
        {/* Left Side - Logo */}
     

       
        <div className={styles.brand} onClick={toggleMenu}>
          <img className={styles.logo} src={logo} alt="BuildMe Logo" />
        </div>
      
      </nav>

      {/* Fullscreen overlay menu */}
      <div className={`${styles.fullscreenMenu} ${menuOpen ? styles.fullscreenMenuOpen : ''}`}>
        <ul className={styles.fullscreenNav}>
          <li><Link to="/" className={styles.fullscreenLink} onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/about" className={styles.fullscreenLink} onClick={toggleMenu}>About</Link></li>
          <li><Link to="/blog" className={styles.fullscreenLink} onClick={toggleMenu}>Blog</Link></li>
          <li><Link to="/services" className={styles.fullscreenLink} onClick={toggleMenu}>Services</Link></li>
          <li><Link to="/consultation" className={styles.fullscreenLink} onClick={toggleMenu}>Free Consultation</Link></li>
        </ul>
      </div>
    </header>
  );
};

export default NavigationMenuBar;