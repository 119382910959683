import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FAQDropdown from '../../components/FAQDropdown';


const faqData = [
  {
    question: "Why is web design important for my small business?",
    answer:
      "Your website is often the first impression potential customers have of your business. A well-designed website can increase credibility, attract more customers, and set you apart from the competition.",
  },
  {
    question: "How long does the web design process take?",
    answer:
      "The timeline for web design depends on the complexity of the project, but for most small business websites, it typically takes between 4 to 6 weeks. This includes design, revisions, and development to ensure the final site meets your expectations.",
  },
  {
    question: "Will my website be mobile-friendly?",
    answer:
      "Yes, every website I design is fully responsive, meaning it will work and look great on all devices, including smartphones, tablets, and desktops. Mobile-friendliness is essential, as many users browse on their phones.",
  },
  {
    question: "Can I make changes to the website myself after it's designed?",
    answer:
      "Yes, I create websites on user-friendly platforms that allow you to make updates yourself. Whether it’s changing text, adding images, or updating services, you’ll have control over your website.",
  },
  {
    question: "How much does web design cost?",
    answer:
      "The cost of web design depends on the features and complexity of the site, but for small businesses, prices typically start around $2,500. We offer affordable solutions that fit within your budget.",
  },
  {
    question: "What information do you need to get started with designing my website?",
    answer:
      "To get started, I need to understand your business goals, target audience, brand style, and the content you’d like to include (services, products, etc.). I’ll guide you through the process and help with any content creation needed.",
  },
  {
    question: "Will my website be optimized for search engines (SEO)?",
    answer:
      "Yes, all of the websites I design are optimized for search engines. This helps improve your site's visibility in search results, making it easier for potential customers to find your business online.",
  },
  {
    question: "What happens if I need help with my website after it's launched?",
    answer:
      "I offer ongoing support and maintenance plans to keep your website up-to-date, secure, and performing well. This includes regular updates, backups, and troubleshooting any issues that arise.",
  },
  {
    question: "Can you integrate features like online booking or e-commerce?",
    answer:
      "Yes, I can integrate various features like online booking systems, e-commerce functionality, and contact forms to help make it easy for customers to interact with your business through the website.",
  },
  {
    question: "How do you ensure my website represents my brand?",
    answer:
      "I work closely with you to understand your business and brand identity. From color schemes to font choices and layouts, I’ll create a custom design that reflects your brand and appeals to your target audience.",
  },
];


const PageContainer = styled('div')(({ theme }) => ({
    fontFamily: 'Noto Sans, sans-serif',
    backgroundColor: '#000000',
    padding: '12.5rem 2.5rem 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh', // Ensure full viewport height
    [theme.breakpoints.down('md')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
  }));

const ContentContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Noto Sans, sans-serif',
  width: '100%',
  maxWidth: '600px', // Reduced maxWidth to match image width
  backgroundColor: '#000000',
  padding: '30px', // Adjusted padding for better spacing
  borderRadius: '20px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginTop: '5px',
  border: '1px solid #ECE7E3',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%', // Decrease content width on smaller screens
  },
}));

const SectionTitle = styled(Typography)({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  margin: '10px auto', // Increase margin to create more space before section
  fontWeight: 'normal',
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)', // Add text shadow
  textAlign: 'left', // Align the title text to the left
});

const SectionContent = styled('div')({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  fontWeight: 'normal',
  marginBottom: '10px', // Add margin to the bottom for consistent spacing
  lineHeight: '1.5',
  maxWidth: '100%', // Adjust maximum width for better readability
  justifyContent: 'center', // Center the content within the container
  textAlign: 'left', // Align text and image to the left
});

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%', // Ensure the image is responsive
  maxWidth: '650px', // Limit the max width of the image
  height: 'auto', // Maintain aspect ratio
  borderRadius: '0px', // Add some border radius for style
  marginTop: '10px', // Add some space between the text and the image
  marginBottom: '10px', // Add some space between the text and the image
  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.5)',

}));

const WebDesignPage = () => {
  return (
    <PageContainer>
      <FAQDropdown faqTitle="Frequently Asked Questions About Web Development" faqs={faqData} />  

  
    </PageContainer>
  );
};

export default WebDesignPage;
