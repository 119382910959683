import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './BlogPost.module.css';

const BlogPost6 = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <title>Web Accessibility: Making the Web Inclusive for All - Build Me A Website</title>
        <meta
          name="description"
          content="Explore the importance of web accessibility and how to design websites that cater to users with disabilities, ensuring an inclusive experience for everyone."
        />
        <meta
          name="keywords"
          content="web accessibility, inclusive design, WCAG, web development, user experience, ADA compliance, ARIA"
        />
        <link rel="canonical" href="https://www.buildmeawebsite.ca/blog/web-accessibility-inclusive-design" />
        <meta property="og:title" content="Web Accessibility: Making the Web Inclusive for All - Build Me A Website" />
        <meta
          property="og:description"
          content="Explore the importance of web accessibility and how to design websites that cater to users with disabilities, ensuring an inclusive experience for everyone."
        />
        <meta property="og:url" content="https://www.buildmeawebsite.ca/blog/web-accessibility-inclusive-design" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://www.buildmeawebsite.ca/og-image-web-accessibility.jpg" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className={styles.header}>
        <h1 className={styles.title}>Web Accessibility: Making the Web Inclusive for All</h1>
      </header>
      <main className={styles.mainContent}>
        <section>
          <p className={styles.firstParagraph}>
            <span className={styles.dropCap}>I</span>
            n today's digital world, <a href="https://www.w3.org/WAI/fundamentals/accessibility-intro/" className={styles.linkStyle}>web accessibility</a> is not just a best practice but a necessity. It ensures that individuals with disabilities can access and use websites effectively. Accessibility not only makes websites inclusive but also enhances the overall user experience for everyone.
          </p>
          <p className={styles.otherParagraph}>
            Web accessibility focuses on making websites usable by people with a range of disabilities, including visual, auditory, motor, and cognitive impairments. Implementing accessibility features allows users to navigate, interact, and consume content without facing barriers.
          </p>
          <p className={styles.otherParagraph}>
            The <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" className={styles.linkStyle}>Web Content Accessibility Guidelines (WCAG)</a> offer a set of standards that web developers can follow to ensure their sites meet accessibility requirements. These guidelines include providing text alternatives for non-text content, creating adaptable layouts, and ensuring keyboard accessibility.
          </p>
        </section>
        <section>
          <p className={styles.otherParagraph}>
            One key aspect of accessibility is ensuring that the content is easily perceivable. This can be achieved by using proper contrast between text and background, providing captions for videos, and using <a href="https://www.w3.org/WAI/fundamentals/accessibility-intro/#assistive-technology" className={styles.linkStyle}>screen reader-friendly HTML elements</a>. Assistive technologies, such as screen readers, rely on well-structured code to convey content accurately.
          </p>
          <p className={styles.otherParagraph}>
            Another critical area is operability. Websites should allow users to navigate and perform tasks using a keyboard alone, without relying on a mouse. Interactive elements like forms, buttons, and links should be clearly labeled to provide context for users with disabilities.
          </p>
        </section>
        <section>
          <p className={styles.otherParagraph}>
            Robust accessibility involves ensuring that content can be interpreted reliably by various assistive technologies. This means using <a href="https://developer.mozilla.org/en-US/docs/Glossary/Semantics" className={styles.linkStyle}>semantic HTML</a>, <a href="https://www.w3.org/WAI/standards-guidelines/aria/" className={styles.linkStyle}>ARIA (Accessible Rich Internet Applications)</a> landmarks, and appropriate attributes that help assistive devices interpret the structure and function of web components.
          </p>
          <p className={styles.otherParagraph}>
            Many organizations are legally required to comply with accessibility standards, such as <a href="https://www.ada.gov/" className={styles.linkStyle}>ADA compliance</a> in the United States. Failure to meet these standards can lead to legal consequences and harm the reputation of a business.
          </p>
        </section>
        <section>
          <p className={styles.otherParagraph}>
            Web accessibility also has SEO benefits. Accessible websites are often better structured, leading to improved search engine rankings. Search engines rely on similar elements as screen readers, such as <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img" className={styles.linkStyle}>alt text</a> for images and properly structured content.
          </p>
          <p className={styles.otherParagraph}>
            In conclusion, making your website accessible is a vital part of modern web development. By following guidelines like <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" className={styles.linkStyle}>WCAG</a> and integrating best practices, you can ensure your website is inclusive, providing a better experience for users of all abilities and meeting legal requirements.
          </p>
        </section>
      </main>
     
    </div>
  );
};

export default BlogPost6;