import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FAQDropdown from '../../components/FAQDropdown';


const faqData = [
  {
    question: "What is DNS and how does it work?",
    answer:
      "DNS (Domain Name System) is like the phonebook of the internet. It translates human-readable domain names (like www.example.com) into IP addresses that computers use to communicate with each other. Without DNS, users would have to enter IP addresses directly to access websites.",
  },
  {
    question: "What is DNS propagation and how long does it take?",
    answer:
      "DNS propagation is the process of updating and spreading DNS records across the global network of DNS servers. It typically takes anywhere from a few hours to 48 hours for changes (like updating nameservers) to propagate fully.",
  },
  {
    question: "What is a DNS record?",
    answer:
      "A DNS record is an entry in the DNS system that maps domain names to IP addresses or other resources. Common DNS records include A records, CNAME records, MX records (for mail servers), and TXT records (for verification and security purposes).",
  },
  {
    question: "What is an A record in DNS?",
    answer:
      "An A record maps your domain name to a specific IP address. This is how DNS translates your domain name into the IP address of the server where your website is hosted.",
  },
  {
    question: "What is a CNAME record in DNS?",
    answer:
      "A CNAME (Canonical Name) record allows you to alias one domain name to another. For example, you might use a CNAME to map blog.example.com to www.example.com.",
  },
  {
    question: "What is DNS caching and how does it affect my website?",
    answer:
      "DNS caching is the process where DNS resolvers temporarily store DNS query results to reduce load times for repeat visitors. While caching improves speed, it may cause delays when propagating changes to your DNS settings (such as IP or nameserver updates).",
  },
  {
    question: "What is a nameserver?",
    answer:
      "A nameserver is a server that stores DNS records for a domain. When someone types your domain name into a browser, the nameserver provides the corresponding IP address so the website can load.",
  },
  {
    question: "What is DNSSEC and do I need it?",
    answer:
      "DNSSEC (Domain Name System Security Extensions) is a security protocol that adds an extra layer of protection to prevent certain types of attacks, such as DNS spoofing or cache poisoning. If you want to improve the security of your domain, especially for e-commerce or sensitive sites, DNSSEC is highly recommended.",
  },
  {
    question: "What is a TTL (Time to Live) in DNS?",
    answer:
      "TTL (Time to Live) is a value that tells DNS servers how long to cache a DNS record before fetching a new one. Lower TTL values mean that changes to your DNS records will propagate more quickly, while higher values reduce the load on your DNS servers.",
  },
  {
    question: "How do I update my DNS records?",
    answer:
      "You can update your DNS records through your domain registrar or hosting provider's control panel. Common updates include modifying the A record to point to a new IP address or adding an MX record to set up email services.",
  },
  {
    question: "What is reverse DNS and do I need it?",
    answer:
      "Reverse DNS is the opposite of regular DNS. It maps an IP address back to a domain name. Reverse DNS is often used by mail servers to verify the identity of incoming emails, helping to prevent spam.",
  },
  {
    question: "What happens if my DNS server goes down?",
    answer:
      "If your DNS server goes down, users won’t be able to resolve your domain name, meaning your website or email services may become inaccessible. It’s essential to have a reliable DNS provider with redundancy to minimize the chances of this happening.",
  },
  {
    question: "Can I transfer my DNS to a different provider?",
    answer:
      "Yes, you can transfer your DNS management to a different provider by updating your domain’s nameservers. Make sure to copy all existing DNS records to the new provider before making the switch to avoid service interruptions.",
  },
  {
    question: "What is a DNS resolver?",
    answer:
      "A DNS resolver is a server that handles DNS lookup requests. When you type a domain name into your browser, the resolver queries other DNS servers to find the correct IP address for the domain.",
  },
  {
    question: "Why is my DNS not resolving properly?",
    answer:
      "DNS may not be resolving correctly due to various reasons such as outdated DNS cache, incorrect DNS records, or issues with the DNS provider. You can flush your local DNS cache or verify your DNS records to troubleshoot.",
  },
];


const PageContainer = styled('div')(({ theme }) => ({
    fontFamily: 'Noto Sans, sans-serif',
    backgroundColor: '#000000',
    padding: '12.5rem 2.5rem 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh', // Ensure full viewport height
    [theme.breakpoints.down('md')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '12.5rem 2.5rem 2.5rem',
    },
  }));

const ContentContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Noto Sans, sans-serif',
  width: '100%',
  maxWidth: '600px', // Reduced maxWidth to match image width
  backgroundColor: '#000000',
  padding: '30px', // Adjusted padding for better spacing
  borderRadius: '20px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginTop: '5px',
  border: '1px solid #ECE7E3',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%', // Decrease content width on smaller screens
  },
}));

const SectionTitle = styled(Typography)({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  margin: '10px auto', // Increase margin to create more space before section
  fontWeight: 'normal',
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)', // Add text shadow
  textAlign: 'left', // Align the title text to the left
});

const SectionContent = styled('div')({
  fontFamily: 'Noto Sans, sans-serif',
  color: 'white',
  fontWeight: 'normal',
  marginBottom: '10px', // Add margin to the bottom for consistent spacing
  lineHeight: '1.5',
  maxWidth: '100%', // Adjust maximum width for better readability
  justifyContent: 'center', // Center the content within the container
  textAlign: 'left', // Align text and image to the left
});

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%', // Ensure the image is responsive
  maxWidth: '650px', // Limit the max width of the image
  height: 'auto', // Maintain aspect ratio
  borderRadius: '0px', // Add some border radius for style
  marginTop: '10px', // Add some space between the text and the image
  marginBottom: '10px', // Add some space between the text and the image
  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.5)',

}));

const DNSMigrationPage = () => {
  return (
    <PageContainer>
      <FAQDropdown faqTitle="Frequently Asked Questions About DNS Migration" faqs={faqData} /> 
      
   

    </PageContainer>
  );
};

export default DNSMigrationPage;
